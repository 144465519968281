/* eslint-disable @typescript-eslint/no-explicit-any */
import { HelpRequestItem } from '../../helpRequest/domain/HelpRequestRepository';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
interface HelpRequest {
  [key: string]: any;
}

export const filterHelpRequestActions = (
  helpRequests: HelpRequest[] | undefined,
  prop: string,
  resolvedProp: any
): HelpRequest[] | undefined => {
  const helpRequestsRes = helpRequests?.filter(
    (helpRequest: HelpRequest) => helpRequest[prop]?.action == resolvedProp
  );

  return helpRequestsRes;
};

export const filterNTVRequested = (noticeRequests: any, resolved: any) => {
  const unresolvedNotice =
    noticeRequests !== null
      ? noticeRequests
          ?.filter((request: any) => request.resolved == resolved)
          .sort((a: any, b: any) => b.resolvedDate - a.resolvedDate)
      : [];
  return unresolvedNotice;
};

const addSortProperty = (requests: any, propDate: any) => {
  if (requests) {
    return [...requests]?.map((request) => ({
      ...request,
      sortDate: new Date(request?.[propDate])
    }));
  }
};

export const orderResolvedRequestsByDate = (
  helpRequests: any,
  leaseAction: any,
  noticeRequests: any
) => {
  const copyOfLease = addSortProperty(leaseAction, 'actionDate');
  const copyNotice = addSortProperty(noticeRequests, 'resolvedDate');
  const copyHelpRequest = addSortProperty(helpRequests, 'resolvedDate');

  if (copyOfLease && copyNotice && copyHelpRequest) {
    return [...copyOfLease, ...copyNotice, ...copyHelpRequest].sort(
      (a, b) => b.sortDate - a.sortDate
    );
  }
};

export interface Action {
  associateName: string;
  actionDate: string;
  action: ActionClass;
  termSelected: string;
  sortDate: string;
}

export interface ActionClass {
  action: string;
}

export const tableBodyContent = (action: Action) => {
  const {
    associateName,
    actionDate,
    termSelected,
    action: { action: actionType }
  } = action;

  const actionMapping: Record<string, any> = {
    completed: {
      completedBy: associateName,
      dateTimeCompleted: actionDate
    },
    voided: {
      voidedBy: associateName,
      dateTimeVoided: actionDate
    },
    'lease-generated': {
      completedBy: associateName,
      dateTimeCompleted: actionDate,
      termSelected
    }
  };

  return actionMapping[actionType] || {};
};

interface Answer {
  answerId: number;
  answerText: string;
  answerValue: string;
  xmlNode: string;
  helpRequest: boolean;
  questionText: string;
}

interface AnswersWindowGuard {
  id: number;
  answerText: string;
  key: boolean;
}

export const getAnswers = (
  helpRequestUnresolve: HelpRequestItem[],
  answersWindowGuard: AnswersWindowGuard[]
) => {
  let answerTextsArray: string[][] = [];

  const data: Answer[][] = helpRequestUnresolve?.map(
    (helpRequest: HelpRequestItem) => helpRequest?.formData?.responses
  );

  answerTextsArray = data?.map((subArray: Answer[]) =>
    subArray?.map((item: Answer) => item?.answerText)
  );

  const hasDefinedValues = answerTextsArray?.some((subArray) =>
    subArray?.some((element) => element !== undefined)
  );

  if (hasDefinedValues) {
    return answerTextsArray;
  }

  const prepFormTexts: { [id: number]: string } = {
    1: 'Yes, window guards are currently installed in my apartment.',
    2: 'No, window guards are not currently installed in my apartment.'
  };

  const answerIdsArray: number[][] = data?.map((subArray: Answer[]) =>
    subArray?.map((item: Answer) => item.answerId)
  );

  answerTextsArray = answerIdsArray?.map((subArray) =>
    subArray?.map((answerId) => {
      if (prepFormTexts[answerId]) {
        return prepFormTexts[answerId];
      }
      const foundText = answersWindowGuard?.find(
        (answerText: AnswersWindowGuard) => answerText.id === answerId
      );
      return foundText ? foundText.answerText : '' + answerId;
    })
  );

  return answerTextsArray;
};

export type HeaderKey =
  | 'submittedBy'
  | 'submittedOn'
  | 'moveOutDate'
  | 'leaseEndDate'
  | 'primaryReasonMovingOut'
  | 'residentType'
  | 'forwardingAddress'
  | 'electronicSignature'
  | 'description'
  | 'statusMRI'
  | 'resolvedBy'
  | 'voidedBy'
  | 'completedBy'
  | 'dateTimeResolved'
  | 'dateTimeVoided'
  | 'dateTimeCompleted'
  | 'termSelected'
  | 'documentStatus'
  | 'generatedOn'
  | 'leaseModifications'
  | 'helpRequestCreatedDate'
  | 'daysNotice'
  | 'residentSelectionForEarlyMoveOut'
  | 'charge'
  | 'chargeType'
  | 'feedback';

export interface Address {
  street: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

type HeaderMap = {
  [key in HeaderKey]: string;
};

export const matchHeaderTable = (propertyHeader: HeaderKey): string => {
  const HEADER: HeaderMap = {
    submittedBy: 'Submitted By',
    submittedOn: 'Submitted On',
    moveOutDate: 'Move Out Date',
    leaseEndDate: 'Lease End Date',
    primaryReasonMovingOut: 'Primary Reason For Moving Out',
    residentType: 'Resident Type You Will Be Moving Into',
    daysNotice: 'Number Of Days Notice',
    forwardingAddress: 'Forwarding Address',
    feedback: 'Feedback / Comments',
    electronicSignature: 'Electronic Signature',
    description: 'Description',
    statusMRI: 'MRI status',
    resolvedBy: 'Resolved By',
    voidedBy: 'Voided By',
    completedBy: 'Completed By',
    dateTimeResolved: 'Date Time Resolved',
    dateTimeVoided: 'Date Time Voided',
    dateTimeCompleted: 'Date Time Completed',
    termSelected: 'Term Selected',
    documentStatus: 'Document Status',
    generatedOn: 'Generated On',
    leaseModifications: 'Lease Modifications',
    helpRequestCreatedDate: '',
    residentSelectionForEarlyMoveOut: 'Resident Selection for Early Move Out',
    charge: 'Charge',
    chargeType: 'Charge Type'
  };

  const header = HEADER[propertyHeader];
  return header;
};

export const displayTwoRowLabels = ({ labels }: any) => {
  if (labels?.length > 4) {
    return {
      width: '60%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexFlow: 'wrap',
      gap: '5px'
    };
  } else {
    return {
      width: '85%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    };
  }
};

export const getHeigth = ({ labels }: any) => {
  return (labels?.includes('NTV Requested') && '40px') || (labels?.length > 4 && '68px') || '40px';
};

export const styleMap: Partial<Record<HeaderKey, React.CSSProperties>> = {
  electronicSignature: { width: '100%', wordBreak: 'break-word' },
  feedback: { width: '100%', wordBreak: 'break-word' },
  description: { width: '100%', wordBreak: 'break-word' },
  statusMRI: { width: '100%', wordBreak: 'break-word' },
  forwardingAddress: { width: '100%', wordBreak: 'break-word' },
  leaseModifications: { width: '100%', wordBreak: 'break-word' }
};

const defaultStyle = { width: '25%', wordBreak: 'break-word' };

export const getStyleForKey = (key: HeaderKey): any => {
  return styleMap[key] ?? defaultStyle;
};

export type HelpRequestContentProps = {
  helpRequestCreatedDate?: string;
  tableBody: Record<HeaderKey, string>;
};

export const headerKeys: HeaderKey[] = [
  'submittedBy',
  'submittedOn',
  'moveOutDate',
  'leaseEndDate',
  'primaryReasonMovingOut',
  'residentType',
  'daysNotice',
  'forwardingAddress',
  'electronicSignature',
  'description',
  'statusMRI',
  'resolvedBy',
  'voidedBy',
  'completedBy',
  'dateTimeResolved',
  'dateTimeVoided',
  'dateTimeCompleted',
  'termSelected',
  'documentStatus',
  'generatedOn',
  'leaseModifications',
  'residentSelectionForEarlyMoveOut',
  'charge',
  'chargeType',
  'feedback'
];

export const isHeaderKey = (key: any): key is HeaderKey => {
  return headerKeys.includes(key as HeaderKey);
};

export const calculateTimeDifference = (givenTime: string): string => {
  const now = dayjs.utc();
  const givenTimeDayjs = dayjs.utc(givenTime);

  const differenceInSeconds = Math.abs(now.diff(givenTimeDayjs, 'second'));

  const timeUnits = [
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'few seconds', seconds: 1 }
  ];

  for (const unit of timeUnits) {
    if (differenceInSeconds >= unit.seconds) {
      const value = Math.floor(differenceInSeconds / unit.seconds);
      if (value === 1 && unit.label === 'day') {
        return `a Day`;
      } else {
        return `${value} ${unit.label}${value !== 1 ? 's' : ''}`;
      }
    }
  }

  return '';
};

export const isMoveOutLessThanLED = (moveOutDate: string, leaseEndDate: string) => {
  const moveOutDateUtc = dayjs.utc(moveOutDate).startOf('day');
  const leaseEndDateUtc = dayjs.utc(leaseEndDate).startOf('day');

  return moveOutDateUtc.isBefore(leaseEndDateUtc);
};

export function isAddressComplete(obj: Address | null): boolean {
  if (!obj) return false;

  return Object.keys(obj).some(
    (key: string) => key !== 'addressLine2' && obj[key as keyof Address] !== undefined
  );
}

/* eslint-disable */
import React, { Fragment, useContext, useState, useEffect, FC } from 'react';

// @ts-ignore
import questionIcon from '../../../../assets/imgs/detailsIcons/questionIcon.png';
// @ts-ignore
import AccordionAssociate from './AccordionAssociate';

import Dialog, { DialogType } from '../../../../shared/dialog/Dialog';
import dayjs from 'dayjs';

// navigation
import { useParams } from 'react-router-dom';
import { DialogDetailsType } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsStatus';
import {
  HelpRequestItem,
  NoticeToVacateItem
} from '../../../../modules/details/helpRequest/domain/HelpRequestRepository';
import { displayConfirmation } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsDialogs';
import { AssociateContext } from '../../../../context/AssociateContext';
import { getAnswers } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import { helpRequestStyles } from './styles';

const HelpRequest: FC<any> = ({
  helpRequestUnresolve,
  unresolvedNTV,
  resolveHelpRequest,
  updateHelpRequestLocally,
  updateNoticeToVacateLocally,
  resolveNoticeToVacate,
  resolveProcessMoveOut,
  resolveHelpRequestQuery,
  resolveNoticeToVacateOnHelpRequest,
  resolveNoticeToVacateQuery,
  resendEmailQuery,
  resendEmail,
  leaseDetailsQuery,
  voidLease,
  voidLeaseQuery
}) => {
  const [dialogDetails, setDialogDetails] = useState<DialogDetailsType>({
    type: DialogType.Confirm,
    headerLabel: '',
    body: '',
    btnLabel1: 'Cancel',
    btnLabel2: 'Confirm',
    isOpen: false,
    btnLabel2Callback: () => Promise.resolve()
  });
  const { id } = useParams();

  const { answersWindowGuard } = useContext<any>(AssociateContext);

  const [selectedButtonIndex, setSelectedButtonIndex] = useState<number | null>(null);

  const [requestTypeSelected, setRequestTypeSelected] = useState<HelpRequestItem>();
  const [requestNoticeToVacateSelected, setRequestNoticeToVacateSelected] =
    useState<NoticeToVacateItem>();

  const handleCloseConfirmDialog = () => {
    setDialogDetails({
      ...dialogDetails,
      isOpen: false
    });
  };

  const handleChildError = (errorDetails: any) => {
    setDialogDetails({
      ...dialogDetails,
      ...errorDetails,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleChildSuccess: any = (
    successDetails: any,
    successAction: () => void,
    btnLabel2Callback: any
  ) => {
    successAction();
    setDialogDetails({
      ...dialogDetails,
      ...successDetails,
      btnLabel2Callback: btnLabel2Callback,
      handleCloseConfirmDialog: handleCloseConfirmDialog
    });
  };

  const handleResolveDialog = (
    enteredNTV: any,
    noticeToVacate: NoticeToVacateItem,
    idx: number
  ) => {
    const enteredInMri = enteredNTV == 'enteredNTV' ? true : false;
    setRequestNoticeToVacateSelected(noticeToVacate);
    setSelectedButtonIndex(idx);

    setDialogDetails({
      ...dialogDetails,
      type: DialogType.Confirm,
      headerLabel: 'Confirmation?',
      body: (
        <div>
          Are you sure you want to resolve this <b>Notice to Vacate?</b>
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Confirm',
      btnLabel2Callback: async () => {
        if (noticeToVacate?.helpRequestRefId?.length) {
          await resolveProcessMoveOut(
            id,
            enteredInMri,
            noticeToVacate.id,
            noticeToVacate?.helpRequestRefId,
            noticeToVacate?.helpRequestId
          );
        } else {
          await resolveNoticeToVacate(
            id,
            enteredInMri,
            noticeToVacate.id,
            noticeToVacate?.helpRequestRefId
          );
        }
      },
      handleCloseConfirmDialog: () =>
        setDialogDetails({
          ...dialogDetails,
          isOpen: false
        })
    });
  };

  const handleResolveHelpRequest = (requestTypeId: HelpRequestItem, idx: number) => {
    setSelectedButtonIndex(idx);
    setRequestTypeSelected(requestTypeId);
    setDialogDetails({
      ...dialogDetails,
      type: DialogType.Confirm,
      headerLabel: 'Confirmation?',
      body: (
        <div>
          Are you sure you want to resolve this <b>Help Request?</b>
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Confirm',
      btnLabel2Callback: async () => {
        await resolveHelpRequest(requestTypeId.id);
      },
      handleCloseConfirmDialog: () =>
        setDialogDetails({
          ...dialogDetails,
          isOpen: false
        })
    });
  };

  const displayConfirmationResolveHelpRequest = async () => {
    displayConfirmation({
      query: resolveHelpRequestQuery,
      successCode: 204,
      successMessage: 'Resolved Successfully!',
      successContent: <div>This Help Request has been successfully resolved.</div>,
      errorMessage: 'Failed to Resolved!',
      errorContent: <div>Sorry! Something went wrong. Please resubmit your request.</div>,
      successAction: () => {},
      refetchCallback: () => {
        updateHelpRequestLocally(requestTypeSelected);
        leaseDetailsQuery.refetch(id);
      },
      handleChildSuccess,
      handleChildError
    });

    resolveHelpRequest(null);
  };
  const displayConfirmationResendEmail = async () => {
    displayConfirmation({
      query: resendEmailQuery,
      successCode: 202,
      successMessage: 'Email was Sent Successfully!',
      successContent: <div>The Email was sent</div>,
      errorMessage: 'Failed to Resend Email!',
      errorContent: <div>Sorry! Something went wrong. Please resubmit your request.</div>,
      successAction: () => {},
      refetchCallback: () => {},
      handleChildSuccess,
      handleChildError
    });

    resendEmail(null);
  };

  const displayConfirmationNoticeToVacate = async () => {
    displayConfirmation({
      query: resolveNoticeToVacateQuery,
      successCode: 204,
      successMessage: 'Resolved Successfully!',
      successContent: <div>This Notice to Vacate has been successfully resolved.</div>,
      errorMessage: 'Failed to Resolved!',
      errorContent: <div>Sorry! Something went wrong. Please resubmit your request.</div>,
      successAction: () => {},
      refetchCallback: () => {
        updateNoticeToVacateLocally(requestNoticeToVacateSelected);
        leaseDetailsQuery.refetch(id);
      },
      handleChildSuccess,
      handleChildError
    });
    resolveNoticeToVacate(null, false, null);
    resolveHelpRequest(null);
  };

  const displayConfirmationProcessMoveOut = async () => {
    displayConfirmation({
      query: resolveNoticeToVacateOnHelpRequest,
      successCode: 204,
      successMessage: 'Resolved Successfully!',
      successContent: <div>This Notice to Vacate has been successfully resolved.</div>,
      errorMessage: 'Failed to Resolved!',
      errorContent: <div>Sorry! Something went wrong. Please resubmit your request.</div>,
      successAction: () => {},
      refetchCallback: () => {
        updateNoticeToVacateLocally(requestNoticeToVacateSelected);
        leaseDetailsQuery.refetch(id);
      },
      handleChildSuccess,
      handleChildError
    });
    resolveNoticeToVacate(null, false, null);
  };
  const handleVoidDocuSign = (docuRefId: string | null) => {
    setDialogDetails({
      ...dialogDetails,
      type: DialogType.Confirm,
      headerLabel: 'Confirmation?',
      body: (
        <div>
          Are you sure you want to void this <b>Lease in DocuSign?</b>
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Confirm',
      btnLabel2Callback: async () => {
        voidLease(id, docuRefId);
      },
      handleCloseConfirmDialog: () =>
        setDialogDetails({
          ...dialogDetails,
          isOpen: false
        })
    });
    voidLease(null);
  };

  const handleResendEmail = (idx: number, docuRefId: string | null) => {
    const refId = docuRefId;
    setSelectedButtonIndex(idx);

    setDialogDetails({
      ...dialogDetails,
      type: DialogType.Confirm,
      headerLabel: 'Confirmation?',
      body: (
        <div>
          Are you sure you want to <b>Resend the Email?</b>
        </div>
      ),
      isOpen: true,
      btnLabel1: 'Cancel',
      btnLabel2: 'Confirm',
      btnLabel2Callback: async () => {
        await resendEmail(refId);
      },
      handleCloseConfirmDialog: () =>
        setDialogDetails({
          ...dialogDetails,
          isOpen: false
        })
    });
    resendEmail(null);
  };

  useEffect(() => {
    displayConfirmationResolveHelpRequest();
  }, [resolveHelpRequestQuery.data]);

  useEffect(() => {
    displayConfirmationResendEmail();
  }, [resendEmailQuery.data]);

  useEffect(() => {
    displayConfirmationNoticeToVacate();
    displayConfirmationProcessMoveOut();
  }, [resolveNoticeToVacateQuery.data, resolveNoticeToVacateOnHelpRequest.data]);

  const handleResolve = (enteredNTV: any, ntvId: any, idx: number) => {
    handleResolveDialog(enteredNTV, ntvId, idx);
  };

  return (
    <>
      <div style={helpRequestStyles.textContainerHelpRequest}>
        <img width={'25px'} src={questionIcon} alt="questionIcon" />
        <span
          style={{
            fontWeight: '600',
            fontSize: '20px',
            paddingLeft: '10px'
          }}>
          HELP REQUEST(S)
        </span>
      </div>
      {unresolvedNTV?.length > 0 &&
        unresolvedNTV.map((noticeToVacate: any, idx: number) => (
          <Fragment key={noticeToVacate.id}>
            <AccordionAssociate
              status={'Submitted'}
              labels={[noticeToVacate?.vacateAutomated ? 'Process Move Out' : 'NTV Requested']}
              subLabel={[
                !noticeToVacate?.vacateAutomated &&
                  (leaseDetailsQuery.data?.requestType === 'notice-to-vacate-no-offer'
                    ? '(No Renewal Offer)'
                    : '(With Renewal Offer)')
              ]}
              helpRequestTypes={[]}
              idx={idx}
              ntvRequestType={noticeToVacate?.requestType}
              resolveNoticeToVacateQuery={resolveNoticeToVacateQuery}
              resolveHelpRequestQuery={resolveHelpRequestQuery}
              selectedButtonIndex={selectedButtonIndex}
              date={dayjs(noticeToVacate?.submittedOn.split(',')[0]).format('MM/DD/YYYY')}
              ntvFlags={{
                earlyTerminationFee: noticeToVacate?.earlyTerminationFee,
                isLeaseEndDateMinusMoveOutDateGreaterThan31:
                  noticeToVacate.isLeaseEndDateMinusMoveOutDateGreaterThan31,
                isMoveOutDateEqualLeaseEndDate: noticeToVacate.isMoveOutDateEqualLeaseEndDate,
                isMoveOutDateGreaterThanLeaseEndDate:
                  noticeToVacate.isMoveOutDateGreaterThanLeaseEndDate,
                isTransferToOtherAvalonBayCommunity:
                  noticeToVacate.isTransferToOtherAvalonBayCommunity,
                isTransferWithinThirtyMiles: noticeToVacate.isTransferWithinThirtyMiles,
                isWithOfferToChangeLeaseEndDate: noticeToVacate.isWithOfferToChangeLeaseEndDate,
                isRemainRentResponsible: noticeToVacate.isRemainRentResponsible,
                isEarlyTermCommunity: noticeToVacate.isEarlyTermCommunity,
                isSubmittedDateMinusOccupancyDateGreaterThan30:
                  noticeToVacate.isSubmittedDateMinusOccupancyDateGreaterThan30,
                isMoveOutDateMinusSubmittedDateGreaterThan30:
                  noticeToVacate.isMoveOutDateMinusSubmittedDateGreaterThan30,
                daysNotice: noticeToVacate.daysNotice,
                requiredDaysNoticeMet: noticeToVacate.requiredDaysNoticeMet
              }}
              tableBody={{
                submittedBy: noticeToVacate?.submittedBy,
                submittedOn: noticeToVacate?.submittedOn,
                moveOutDate: dayjs(noticeToVacate?.moveOutDate.split(',')[0]).format('MM/DD/YYYY'),
                leaseEndDate: dayjs(noticeToVacate?.leaseEndDate.split(',')[0]).format(
                  'MM/DD/YYYY'
                ),
                primaryReasonMovingOut: noticeToVacate?.primaryReason,
                residentType: noticeToVacate?.moveInType,
                residentSelectionForEarlyMoveOut: 'Here',
                charge: noticeToVacate?.charge,
                chargeType: noticeToVacate?.chargeType,
                forwardingAddress: {
                  street: noticeToVacate?.forwardingAddress?.street,
                  city: noticeToVacate?.forwardingAddress?.city,
                  addressLine2: noticeToVacate?.forwardingAddress?.addressLine2,
                  state: noticeToVacate?.forwardingAddress?.state,
                  zip: noticeToVacate?.forwardingAddress?.zip,
                  country: noticeToVacate?.forwardingAddress?.country
                },
                electronicSignature: noticeToVacate?.signature,
                MRIStatus: noticeToVacate?.vacateAutomated ? false : true,
                isHelpRequest: true,
                daysNotice: noticeToVacate?.daysNotice,
                isProcessMoveOut: noticeToVacate?.vacateAutomated,
                feedback: noticeToVacate?.feedback ?? 'No Feedback / Comments'
              }}
              isRequiredDaysNoticeMet={
                noticeToVacate?.daysNotice >= noticeToVacate?.requiredDaysNoticeMet
              }
              defaultOpen={idx == 0 ? true : false}
              handleResolve={(enteredNTV: any) => handleResolve(enteredNTV, noticeToVacate, idx)}
            />
          </Fragment>
        ))}
      {helpRequestUnresolve?.length > 0 &&
        helpRequestUnresolve.map((helpRequest: HelpRequestItem, idx: number) => (
          <Fragment key={helpRequest.id}>
            <AccordionAssociate
              labels={helpRequest?.helpRequestTypes?.map(
                ({ displayName }: { displayName: string }) => displayName
              )}
              status={'Submitted'}
              helpRequestTypes={helpRequest?.helpRequestTypes?.map(
                (helpRequestType) => helpRequestType.key
              )}
              date={dayjs(helpRequest.createdDate).format('MM/DD/YYYY')}
              tableBody={
                Boolean(
                  helpRequest.helpRequestTypes?.find((hrt) => hrt.key === 'lease-modification')
                )
                  ? {
                      documentStatus: helpRequest?.documentStatus,
                      generatedOn: dayjs(helpRequest?.createdDate).format('MM/DD/YYYY'),
                      leaseModifications: helpRequest?.notes,
                      MRIStatus: false,
                      helpRequestCreatedDate: helpRequest.createdDate,
                      windowGuard: helpRequest?.formData?.formName?.includes('Window Guard'),
                      isLeaseModification: true,
                      isHelpRequest: true
                    }
                  : {
                      submittedBy: `${helpRequest?.submittedBy}`,
                      submittedOn: dayjs(helpRequest?.createdDate).format('MM/DD/YYYY'),
                      description: helpRequest?.notes,
                      MRIStatus: false,
                      windowGuard: helpRequest?.formData?.formName?.includes('Window Guard'),
                      isLeaseModification: false,
                      isHelpRequest: true
                    }
              }
              residentResponse={
                helpRequest?.formData?.formName?.includes('Window Guard')
                  ? getAnswers(helpRequestUnresolve, answersWindowGuard)[idx]
                  : []
              }
              idx={idx}
              leaseType={leaseDetailsQuery?.data?.lease?.leaseType.toUpperCase()}
              requireWetSignature={leaseDetailsQuery?.data?.requireWetSignature}
              docusignRefId={helpRequest.docusignRefId}
              occupantDetails={helpRequest.signerStatus}
              selectedButtonIndex={selectedButtonIndex}
              voidLeaseQuery={voidLeaseQuery}
              handleVoidDocuSign={() => handleVoidDocuSign(helpRequest.docusignRefId)}
              handleResendEmail={() => handleResendEmail(idx, helpRequest.docusignRefId)}
              resolveHelpRequestQuery={resolveHelpRequestQuery}
              defaultOpen={unresolvedNTV.length == 0 ? idx == 0 && true : false}
              handleResolveHelpRequest={() => handleResolveHelpRequest(helpRequest, idx)}
            />
          </Fragment>
        ))}
      <div style={{ paddingTop: '1.5%' }}></div>
      <Dialog {...dialogDetails} />
    </>
  );
};

export default HelpRequest;

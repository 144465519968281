import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { patchHelpRequest } from '../../../../../modules/details/helpRequest/application/patchtHelpRequest';
import { createAPIHelpRequestRepository } from '../../../../../modules/details/helpRequest/infrastructure/APIHelpRequest';
import { putNoticeToVacate } from '../../../../../modules/details/helpRequest/application/putNoticeToVacate';
import { putResendEmail } from '../../../../../modules/details/helpRequest/application/putResendEmail';

const repository = createAPIHelpRequestRepository();

export const useResolveHelpRequest = () => {
  const [helpRequestId, setHelpRequestId] = useState<string | null>(null);

  const [leaseId, setLeaseId] = useState<string | null>(null);
  const [enteredInMri, setEnteredInMri] = useState<boolean>(false);
  const [noticeToVacateId, setNoticeToVacateId] = useState<string | null>(null);
  const [helpRequestRefId, setHelpRequestRefId] = useState<string | null>(null);

  const [refId, setRefId] = useState<string | null>(null);

  const resolveHelpRequestQuery = useQuery({
    queryKey: ['resolveHelpRequest', helpRequestId],
    queryFn: () => patchHelpRequest(repository, helpRequestId),
    enabled: !!helpRequestId
  });

  const resolveNoticeToVacateOnHelpRequest = useQuery({
    queryKey: ['resolveNoticeToVacateOnHelpRequest', noticeToVacateId],
    queryFn: () =>
      putNoticeToVacate(repository, leaseId, enteredInMri, noticeToVacateId, helpRequestRefId),
    enabled: !!noticeToVacateId && resolveHelpRequestQuery.isSuccess
  });

  const resolveNoticeToVacateQuery = useQuery({
    queryKey: ['resolveNoticeToVacate', noticeToVacateId],
    queryFn: () =>
      putNoticeToVacate(repository, leaseId, enteredInMri, noticeToVacateId, helpRequestRefId),
    enabled: !!noticeToVacateId
  });

  const resendEmailQuery = useQuery({
    queryKey: ['resendEmail', refId],
    queryFn: () => putResendEmail(repository, refId),
    enabled: !!refId,
    gcTime: 0
  });

  const resolveHelpRequest = (helpRequestId: string) => setHelpRequestId(helpRequestId);
  const resolveNoticeToVacate = (
    leaseId: string,
    enteredInMri: boolean,
    noticeToVacateId: string,
    helpRequestRefId: string
  ) => {
    setLeaseId(leaseId);
    setEnteredInMri(enteredInMri);
    setNoticeToVacateId(noticeToVacateId);
    setHelpRequestRefId(helpRequestRefId);
  };

  const resolveProcessMoveOut = (
    leaseId: string,
    enteredInMri: boolean,
    noticeToVacateId: string,
    helpRequestRefId: string,
    helpRequestId: string
  ) => {
    setLeaseId(leaseId);
    setEnteredInMri(enteredInMri);
    setNoticeToVacateId(noticeToVacateId);
    setHelpRequestRefId(helpRequestRefId);
    setHelpRequestId(helpRequestId);
  };

  const resendEmail = (refId: string) => setRefId(refId);

  return {
    resolveHelpRequestQuery,
    resolveNoticeToVacateOnHelpRequest,
    resolveNoticeToVacateQuery,
    resendEmailQuery,
    //Methods
    resolveHelpRequest,
    resolveNoticeToVacate,
    resolveProcessMoveOut,
    resendEmail
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';

import { withStyles } from '@mui/styles';

import { helpRequestStyles } from './styles';

import WindowGuardResponse from './WindowGuardResponse';
import HelpRequestContent from './HelpRequestContent';
import NoticeToVacateContent from '../noticeToVacate/NoticeToVacateContent';
import HelpRequestHeader from './HelpRequestHeader';
import { getHeigth } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';
import LeaseModificationRequest from './LeaseModification';
import ProcessMoveOutContent from './ProcessMoveOutContent';
import isHelpRequest from '../../../../utils/isHelpRequest';

const AccordionAssociate = ({
  labels,
  subLabel,
  title,
  status,
  date,
  tableBody,
  defaultOpen,
  handleResolve,
  residentResponse,
  docusignRefId,
  idx,
  selectedButtonIndex,
  voidLeaseQuery,
  handleVoidDocuSign,
  handleResolveHelpRequest,
  handleResendEmail,
  resolveHelpRequestQuery,
  resolveNoticeToVacateQuery,
  occupantDetails,
  leaseType,
  requireWetSignature,
  isRequiredDaysNoticeMet,
  ntvRequestType,
  helpRequestTypes,
  ntvFlags
}: any) => {
  const [expanded, setExpanded] = useState(defaultOpen || false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(defaultOpen || false);
  }, [defaultOpen]);

  const StyledAccordionSummary = withStyles({
    root: {
      minHeight: `${getHeigth({ labels })}`,
      backgroundColor: '#F6F6F7',
      border: '1px',
      borderStyle: 'solid',
      borderRadius: '3px',
      borderColor: '#979AA0',
      '&.Mui-expanded': {
        margin: '0',
        minHeight: `${getHeigth({ labels })}`,
        backgroundColor: '#F6F6F7',
        '& div': {
          margin: '2px 0'
        }
      },
      '& div': {
        margin: '2px 0'
      }
    },
    expandIcon: {
      order: -1
    }
  })(AccordionSummary);

  return (
    <>
      <Accordion
        sx={helpRequestStyles.accordionContainer}
        expanded={expanded}
        onChange={handleChange}>
        <StyledAccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header">
          <HelpRequestHeader
            labels={labels}
            subLabel={subLabel}
            status={status}
            title={title}
            date={date}
          />
        </StyledAccordionSummary>
        <AccordionDetails sx={helpRequestStyles.accordionDetailsCard}>
          <div style={helpRequestStyles.accordionFlexDirection}>
            {isHelpRequest(helpRequestTypes, title) &&
              !tableBody.windowGuard &&
              !tableBody.isLeaseModification &&
              !tableBody.isProcessMoveOut && <HelpRequestContent tableBody={tableBody} />}

            {status == 'Submitted' &&
              tableBody?.description?.length > 0 &&
              !tableBody?.windowGuard &&
              !tableBody?.isLeaseModification &&
              !tableBody.isProcessMoveOut && (
                <>
                  <div style={helpRequestStyles.accordionButtonContainer}>
                    {resolveHelpRequestQuery?.isFetching && selectedButtonIndex === idx ? (
                      <Button
                        variant="contained"
                        sx={{ textTransform: 'capitalize' }}
                        style={helpRequestStyles.DisabledButton}
                        disabled={true}>
                        <CircularProgress sx={{ color: '#1D2F5C' }} size={20} />
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{ textTransform: 'capitalize' }}
                        style={helpRequestStyles.PrimaryButton}
                        onClick={handleResolveHelpRequest}>
                        <p style={helpRequestStyles.resolveButtonStyle}>Resolve</p>
                      </Button>
                    )}
                  </div>
                </>
              )}
          </div>
          {tableBody?.isLeaseModification && (
            <LeaseModificationRequest
              docusignRefId={docusignRefId}
              tableBody={tableBody}
              isResolved={tableBody?.resolvedBy != undefined}
              residentResponse={residentResponse}
              description={tableBody?.notes}
              handleResendEmail={handleResendEmail}
              handleVoidDocuSign={handleVoidDocuSign}
              handleResolveHelpRequest={handleResolveHelpRequest}
              voidLeaseQuery={voidLeaseQuery}
              resolveHelpRequestQuery={resolveHelpRequestQuery}
              resendEmailQuery={resolveHelpRequestQuery}
              selectedButtonIndex={selectedButtonIndex}
              occupantDetails={occupantDetails}
              idx={idx}
              leaseType={leaseType}
              requireWetSignature={requireWetSignature}
            />
          )}
          {!tableBody?.isLeaseModification && !tableBody.isProcessMoveOut && (
            <NoticeToVacateContent
              isRequiredDaysNoticeMet={isRequiredDaysNoticeMet}
              tableBody={tableBody}
              helpRequestTypes={helpRequestTypes}
              resolveNoticeToVacateQuery={resolveNoticeToVacateQuery}
              isResolved={tableBody?.resolvedBy != undefined}
              handleResolve={handleResolve}
              selectedButtonIndex={selectedButtonIndex}
              idx={idx}
              ntvRequestType={ntvRequestType}
              ntvFlags={{ ...ntvFlags }}
            />
          )}
          {tableBody.isProcessMoveOut && (
            <ProcessMoveOutContent
              tableBody={tableBody}
              isRequiredDaysNoticeMet={isRequiredDaysNoticeMet}
              isResolved={tableBody?.resolvedBy != undefined}
              handleResolve={handleResolve}
              resolveHelpRequestQuery={resolveHelpRequestQuery}
              selectedButtonIndex={selectedButtonIndex}
              idx={idx}
            />
          )}
          {tableBody?.windowGuard && !tableBody?.isLeaseModification && (
            <WindowGuardResponse
              tableBody={tableBody}
              isResolved={tableBody?.resolvedBy != undefined}
              residentResponse={residentResponse}
              description={tableBody?.notes}
              resolveHelpRequestQuery={resolveHelpRequestQuery}
              handleResolveHelpRequest={handleResolveHelpRequest}
              selectedButtonIndex={selectedButtonIndex}
              idx={idx}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <div style={{ height: '20px' }}></div>
    </>
  );
};

export default AccordionAssociate;

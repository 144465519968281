/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import TableCell from '@mui/material/TableCell';

const TableCellContent = ({ align, children, sx, component = 'th', scope = 'row' }: any) => {
  return (
    <TableCell
      align={align}
      component={component}
      scope={scope}
      sx={{ color: '#31343A', border: 'none', padding: '0 8px', fontSize: '16px', ...sx }}>
      {children}
    </TableCell>
  );
};

export default TableCellContent;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import TableCell from '@mui/material/TableCell';

const TableCellTitle = ({ children, sx, align }: any) => {
  return (
    <TableCell
      align={align}
      sx={{
        fontSize: '14px',
        border: 'none',
        padding: '8px 8px 0',
        fontWeight: 700,
        color: '#31343A',
        ...sx
      }}>
      {children}
    </TableCell>
  );
};

export default TableCellTitle;

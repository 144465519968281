/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Box from '@mui/material/Box';

const BlueText = ({ text }: any) => {
  return (
    <Box
      component="p"
      sx={{
        border: 'none',
        fontWeight: 700,
        fontSize: '14px',
        color: '#0288D1',
        padding: '0 8px'
      }}>
      {text}
    </Box>
  );
};

export default BlueText;

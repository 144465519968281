import { isAddressComplete } from '../modules/details/leaseDetails/domain/LeaseDetailsHelpRequest';

interface ForwardingAddress {
  street: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

const formatForwardingAddress = (address: ForwardingAddress): string => {
  if (isAddressComplete(address)) {
    if (address?.addressLine2) {
      return `${address.street}, ${address.addressLine2}, ${address.city}, ${address.state} ${address.zip} - ${address.country}`;
    } else {
      return `${address.street}, ${address.city}, ${address.state} ${address.zip} - ${address.country}`;
    }
  } else {
    return 'Unknown';
  }
};

export default formatForwardingAddress;

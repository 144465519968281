enum HelpRequestTitles {
  FORCED_COMPLETE = 'Forced Complete',
  VOID_IN_DOCUSIGN = 'Void in DocuSign',
  GENERATED_LEASE_IN_DOCUSIGN = 'Generated Lease in DocuSign'
}

enum HelpRequestExclusions {
  PROCESS_MOVE_OUT = 'process-move-out',
  WINDOW_GUARD = 'window-guard',
  LEASE_MODIFICATION = 'lease-modification'
}

function isStandardHelpRequest(helpRequestArr: string[], title: string): boolean {
  if (
    title === HelpRequestTitles.FORCED_COMPLETE ||
    title === HelpRequestTitles.VOID_IN_DOCUSIGN ||
    title === HelpRequestTitles.GENERATED_LEASE_IN_DOCUSIGN
  ) {
    return true;
  }

  if (helpRequestArr && helpRequestArr.length > 0) {
    return (
      !helpRequestArr.includes(HelpRequestExclusions.PROCESS_MOVE_OUT) ||
      !helpRequestArr.includes(HelpRequestExclusions.WINDOW_GUARD) ||
      !helpRequestArr.includes(HelpRequestExclusions.LEASE_MODIFICATION)
    );
  }

  return false;
}

export default isStandardHelpRequest;

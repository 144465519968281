/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import { Link } from 'react-router-dom';
import { NavigateBefore } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';

// @ts-ignore
import { leaseDetailsStyles } from './styles';

// @ts-ignore
import ChipStatus from '../../../../shared/chipStatus/ChipStatus';
import {
  LeaseDetails,
  isTermsDurationVisible
} from '../../../../modules/details/leaseDetails/domain/LeaseDetails';
import { isHOU } from '../../../../modules/details/leaseDetails/domain/LeaseDetailsSubheader';

import { isLeaseExpired } from '../../../../modules/dashboard/residents/domain/ResidentTable';
import { useParams } from 'react-router-dom';
import { isInvalidLeaseType } from '../../../../modules/details/leaseForms/domain/SelectLease';

type ResidentFullName = {
  firstName?: string;
  lastName?: string;
};

const LeaseDetailsSubHeader = ({
  leaseDetails,
  generateLeaseForms = false
}: {
  leaseDetails: LeaseDetails;
  generateLeaseForms?: boolean;
}) => {
  const docusignNumberPending = leaseDetails?.resident?.docusignNumberPending;
  const docusignTotalSignatures = leaseDetails?.resident?.docusignTotalSignatures;
  const { leaseId = '0' } = useParams();

  const getHeaderLabel = () => {
    if (
      leaseDetails?.requestType === 'notice-to-vacate-no-offer' &&
      leaseDetails?.resident?.status !== 'ntv-completed'
    ) {
      return ChipStatus(leaseDetails?.requestType, leaseDetails?.resident?.lastModified, true);
    }
    return ChipStatus(leaseDetails?.resident?.status, leaseDetails?.resident?.lastModified, true);
  };

  const parsedFullName = (resident: ResidentFullName) => {
    const noNullValuesArr = [resident?.firstName, resident?.lastName].filter((item) => !!item);
    const fullName = noNullValuesArr.join(' ');

    return fullName;
  };

  return (
    <>
      <div
        style={{
          ...leaseDetailsStyles.Header,
          backgroundColor: 'white'
        }}>
        <Grid container direction={'column'} alignItems={'stretch'}>
          <Grid item xs={4}>
            {!generateLeaseForms && (
              <Link to="/dashboard" className="pendo-bd" style={leaseDetailsStyles.BackButton}>
                <NavigateBefore />
                Back
              </Link>
            )}
          </Grid>
          <Grid
            container
            direction={'row'}
            sx={{ marginTop: '1rem', justifyContent: 'space-between' }}>
            <Grid item xs={8}>
              <div
                style={{
                  display: 'flex',
                  justifyItems: 'center',
                  alignItems: 'center'
                }}>
                <div>
                  <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                    <h2 style={leaseDetailsStyles.H2}>
                      {leaseDetails?.resident && parsedFullName(leaseDetails?.resident)}
                      {leaseDetails?.responsiblePersons?.length
                        ? parsedFullName(leaseDetails?.responsiblePersons[0])
                        : ''}
                    </h2>
                    <div style={{ paddingLeft: '15px' }}>
                      <p
                        style={{
                          marginBottom: '5px',
                          color: '#00A3E0',
                          fontWeight: 700,
                          fontSize: '16px',
                          textTransform: 'capitalize'
                        }}>
                        {!isInvalidLeaseType(leaseDetails) && leaseDetails?.lease?.leaseType}
                      </p>
                    </div>
                  </div>

                  {generateLeaseForms && (
                    <div
                      style={{
                        fontWeight: 700,
                        color: ' #31343A',
                        fontSize: '16px',
                        paddingTop: '2%'
                      }}>
                      {leaseId}
                    </div>
                  )}
                </div>
                <div style={{ display: 'flex' }}>
                  {isHOU(leaseDetails?.lease?.monthlyCharges) && !generateLeaseForms ? (
                    <div className="pendo-hou" style={{ paddingLeft: '10px', paddingTop: '6px' }}>
                      {ChipStatus('HOU')}
                    </div>
                  ) : (
                    <></>
                  )}
                  {leaseDetails?.isLegal && (
                    <div className="pendo-legal" style={{ paddingLeft: '10px', paddingTop: '6px' }}>
                      {ChipStatus('LEGAL')}
                    </div>
                  )}
                </div>
              </div>
              <span style={leaseDetailsStyles.PropertyId}>{leaseDetails?.resident?.leaseId}</span>
            </Grid>
            {!generateLeaseForms && isInvalidLeaseType(leaseDetails) && (
              <Grid container direction={'column'} item lg={2} style={leaseDetailsStyles.RightAign}>
                <p style={{ fontSize: '18px' }}>Renewal/NTV Status</p>
                <Grid item style={{ ...leaseDetailsStyles.FontSmall, display: 'flex' }}>
                  <Box style={{ paddingRight: '3%' }}>
                    {leaseDetails?.lease?.leaseExpirationDate &&
                      isLeaseExpired(leaseDetails.lease.leaseExpirationDate) &&
                      ChipStatus('forced-mtm', '', true)}
                  </Box>
                  <Box>
                    {leaseDetails?.resident?.status &&
                      leaseDetails?.resident?.status !== '' &&
                      getHeaderLabel()}
                  </Box>
                </Grid>
                {leaseDetails?.resident?.status === 'lease-generated' && (
                  <Grid
                    item
                    style={{
                      ...leaseDetailsStyles.Signatures,
                      marginRight:
                        (leaseDetails?.resident?.lastModified?.length ?? 0 > 5) ? '2rem' : '1.2rem'
                    }}>
                    {docusignNumberPending} of {docusignTotalSignatures} Signatures Pending
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid
              container
              item
              lg={6}
              direction={'row'}
              style={{
                paddingTop: '15px',
                display: 'flex',
                flexDirection: 'row'
              }}>
              <div>
                <Button variant="contained" style={leaseDetailsStyles.NonClickableButton}>
                  {leaseDetails?.community?.name}
                </Button>
              </div>
              <div style={{ ...leaseDetailsStyles.SmallBox, paddingLeft: '10px' }}>
                {generateLeaseForms ? 'PBU-L ' : ' Apartment '}
                {leaseDetails?.unit?.buildingId + '-' + leaseDetails?.unit?.unitId}
                <ul style={leaseDetailsStyles.Points}>
                  <li style={leaseDetailsStyles.NoBullet}>
                    {leaseDetails?.unit?.bedroom} bedrooms
                  </li>
                  <li>{leaseDetails?.unit?.bathroom} baths</li>
                  <li>{leaseDetails?.unit?.squareFeet} Sq.ft</li>
                </ul>
              </div>
            </Grid>
            <Grid
              container
              direction={'column'}
              item
              lg={2}
              style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              {isTermsDurationVisible(leaseDetails?.resident?.status) &&
              leaseDetails?.resident?.term ? (
                <div style={leaseDetailsStyles.FontSmall}>
                  Term Selected -{' '}
                  <span style={{ fontWeight: '600' }}>{leaseDetails?.resident?.term} months</span>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LeaseDetailsSubHeader;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@mui/material';
import React, { FC, useContext, useEffect, useState } from 'react';
import CheckboxesList from '../../../../dashboard/components/filters/CheckboxesList';
import {
  FormObject,
  GroupedData
} from '../../../../../modules/details/leaseForms/domain/LeaseModification';
import { AssociateContext } from '../../../../../context/AssociateContext';
import { useSpring, animated, config } from '@react-spring/web';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface selectLeaseProps {
  handleClose: () => void;
  handleStep: (value: number) => void;
  isSecondScreen?: boolean;
}
const SelectLeaseModification: FC<selectLeaseProps> = ({
  handleClose,
  handleStep,
  isSecondScreen = false
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {
    leaseDetails,
    leasePayload,
    individualLeaseForm,
    metadataForms,
    currentStep,
    lastStep,
    currentSelection
  } = useContext<any>(AssociateContext);

  type CheckedItems = {
    ManageFeesAndCharges: Map<string, boolean>;
    ManageLeaseAttributes: Map<string, boolean>;
    ManageRoommates: Map<string, boolean>;
    ManageDiscountsAndConcessions: Map<string, boolean>;
    ManageSecurityDeposits: Map<string, boolean>;
    AffordableHousing: Map<string, boolean>;
    OtherActions: Map<string, boolean>;
  };

  const createInitialStateCheckedItems = (dynamicInitialState: any[]): CheckedItems => {
    return dynamicInitialState.reduce((acc, group) => {
      Object.entries(group).forEach(([groupName, items]: [string, any]) => {
        const groupMap = new Map<string, boolean>(items.map(([name]: any) => [name, false]));
        acc[groupName] = groupMap;
      });
      return acc;
    }, {} as CheckedItems);
  };

  const [checkedItems, setCheckedItems] = useState<any>();

  const [groupedData, setGroupedData] = useState<any>();

  const geInitialState = (state: any, result: any) => {
    const keysArray = Object.keys(result);

    const resultArr = keysArray.map((key, idx) => {
      return { [key]: state[idx] };
    });

    const initialStateCheck = createInitialStateCheckedItems(resultArr);
    setCheckedItems(initialStateCheck);
    if (currentSelection === 'Individual Lease') {
      setPrevValues(initialStateCheck, result);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    selection: keyof CheckedItems | any
  ) => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    setCheckedItems({
      ...checkedItems,
      [selection]: new Map(checkedItems[selection].set(item, isChecked))
    });

    filterByRequests(selection);
  };

  const findSelectectedFilters = (selectedFilters: any, filters: any) => {
    selectedFilters?.length > 0 &&
      selectedFilters
        ?.split(',')
        .map((filter: any) => filter.replace(/\s/g, ''))
        .forEach((k: any) => {
          if (filters.has(k)) {
            filters.set(k, true);
          }
        });

    return filters;
  };

  const filterByRequests = (filterBy: keyof CheckedItems) => {
    let requestTypes = '';

    for (const [key, value] of checkedItems[filterBy]) {
      if (value) {
        requestTypes = `${requestTypes === '' ? key : requestTypes + ',' + key}`;
      }
    }

    individualLeaseForm({ metadataForms: { ...metadataForms, [filterBy]: requestTypes } });
    return requestTypes;
  };

  const initializeState = async (initialStateCheck: any, result: any) => {
    const initialState = Object.keys(result).map((key) => ({
      [key]: new Map(initialStateCheck[key])
    }));

    return initialState;
  };

  const extractValuesByKey = (data: any, targetKey: any) => {
    return data
      .map((obj: any) => {
        const key = Object.keys(obj)[0];
        const values = obj[key];

        return { key, values };
      })
      .filter((entry: any) => entry.key === targetKey);
  };

  const execute = async (initialStateCheck: any, result: any) => {
    try {
      const initializedState = await initializeState(initialStateCheck, result);

      const resultObject: any = {};

      for (const key of Object.keys(result)) {
        resultObject[key] = await findSelectectedFilters(
          metadataForms[key],
          extractValuesByKey(initializedState, key)[0].values
        );
      }
      return resultObject;
    } catch (error) {
      console.error(error);
    }
  };

  const setPrevValues = (initialStateCheck: any, result: any) => {
    if ((metadataForms && !Object.keys(metadataForms).length) || !metadataForms) return;

    const prevState = execute(initialStateCheck, result);

    prevState.then((res) => {
      setCheckedItems(res);
    });
  };

  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({
    ManageFeesAndCharges: true,
    ManageLeaseAttributes: true,
    ManageRoommates: true,
    ManageDiscountsAndConcessions: true,
    MiscellaneousActions: true,
    ManageSecurityDeposits: true,
    AffordableHousing: true,
    OtherActions: true
  });

  const toggleAccordion = (key: string) => {
    if (!isSecondScreen) return;
    setIsOpen({
      ...isOpen,
      [key]: !isOpen[key]
    });
  };

  const springProps = useSpring({
    height: isOpen ? 'auto' : 0,
    opacity: isOpen ? 1 : 0,
    y: isOpen ? 0 : 20,
    config: config.wobbly
  });

  const initialStateOpen = () => {
    setIsOpen({
      ManageFeesAndCharges: false,
      ManageLeaseAttributes: false,
      ManageRoommates: false,
      MiscellaneousActions: false,
      ManageDiscountsAndConcessions: false,
      ManageSecurityDeposits: false,
      AffordableHousing: false,
      OtherActions: false
    });
  };

  const renderArrows = (category: string) => {
    if (!isSecondScreen) return;
    return isOpen[category] ? <ArrowDropDownOutlinedIcon /> : <ArrowRightOutlinedIcon />;
  };

  const getCurrentSelection = (category: string) => {
    if (metadataForms && isSecondScreen) {
      if (!metadataForms[category]) {
        return 0;
      }
      const selection = metadataForms[category]?.split(',').length;
      return selection;
    }
    return 0;
  };

  // Sanitaze data
  const toCamelCase = (str: string): string => {
    return str.replace(/\s(.)/g, (_, match) => match.toUpperCase()).replace(/\s/g, '');
  };

  const transformGroupedDataToArray = (
    groupedData: GroupedData
  ): Array<Array<[string, boolean]>> => {
    const data: Array<Array<[string, boolean]>> = Object.entries(groupedData).map(
      ([groupName, groupItems]) => {
        return groupItems.map((item) => [toCamelCase(item.value), false]);
      }
    );
    return data;
  };

  const groupDataByFormGroupName = (data: FormObject[]): GroupedData => {
    const result: GroupedData = data.reduce((acc, current) => {
      const groupName = toCamelCase(current.formGroupName);

      if (!acc[groupName]) {
        acc[groupName] = [];
      }

      acc[groupName].push({
        name: current.formTitle,
        displayName: current.formTitle,
        value: current.formTitle.replace(/\s/g, '')
      });

      return acc;
    }, {} as GroupedData);

    const initialStates = transformGroupedDataToArray(result);

    geInitialState(initialStates, result);

    leaseDetails({ leasePayload, formsByCategory: result });

    return result;
  };

  const [showFullList, setShowFullList] = useState<{ [key: string]: boolean }>({
    ManageFeesAndCharges: false,
    ManageLeaseAttributes: false,
    ManageRoommates: false,
    ManageDiscountsAndConcessions: false,
    ManageSecurityDeposits: false,
    AffordableHousing: false,
    OtherActions: false
  });

  const handleShowList = (key: string) => {
    setShowFullList({
      ...showFullList,
      [key]: !showFullList[key]
    });
  };

  useEffect(() => {
    isSecondScreen && initialStateOpen();
    !isSecondScreen && currentStep({ displaySelection: false, currentSelection, lastStep });
  }, []);

  useEffect(() => {
    const groupedData = groupDataByFormGroupName(leasePayload['forms']);

    if (leasePayload?.lease?.leaseType?.toLowerCase() !== 'corporate') {
      groupedData.ManageRoommates = groupedData?.ManageRoommates?.filter(
        (checkbox) => checkbox.name !== 'Corporate Occupant Change'
      );
    }
    setGroupedData(groupedData);
  }, []);

  return (
    <div>
      {!isSecondScreen && (
        <div style={{ padding: '2%' }}>
          <div style={{ fontSize: '24px', color: '#31343A', textTransform: 'capitalize' }}>
            Generate individual lease forms (select all that apply)
          </div>
          <div style={{ fontSize: '16px', color: '#72767F', paddingTop: '1%' }}>
            Forms will be automatically compiled into a DocuSign package.
          </div>
        </div>
      )}

      {groupedData && Object.keys(groupedData).length > 0 && (
        <div style={{ height: isSecondScreen ? 'auto' : 'auto', padding: '2%' }}>
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', alignItems: 'baseline' }}>
            {Object.entries(groupedData).map(([key, value]) => (
              <div
                key={key}
                style={{
                  paddingBottom: isSecondScreen ? '2%' : '0%',
                  width: isSecondScreen ? '50%' : '33%'
                }}>
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={() => toggleAccordion(key)}>
                  {renderArrows(key)}
                  {key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, (c) => c.toUpperCase())}
                  {isSecondScreen && `(${getCurrentSelection(key)})`}
                </div>
                <animated.div
                  style={{
                    display: isOpen[key] ? 'block' : 'none',
                    overflow: 'hidden',
                    height: isSecondScreen ? 'auto' : showFullList[key] ? 'auto' : '260px'
                    // ...springProps
                  }}>
                  <CheckboxesList
                    checkedItems={checkedItems?.[key]}
                    handleChange={(e) => handleChange(e, key)}
                    filterCheckboxes={groupedData && groupedData[key]}
                    generateLeaseForms={true}
                  />
                </animated.div>
                {!isSecondScreen && (
                  <div style={{ paddingBottom: '4%' }}>
                    {groupedData && groupedData[key].length > 5 && (
                      <div style={{ padding: '3% 0 5% 3%' }}>
                        {showFullList[key] ? (
                          <Button
                            size="small"
                            sx={{ color: '#1D2F5C' }}
                            onClick={() => handleShowList(key)}>
                            <ExpandLess /> show less
                          </Button>
                        ) : (
                          <Button
                            size="small"
                            sx={{ color: '#1D2F5C' }}
                            onClick={() => handleShowList(key)}>
                            <ExpandMore /> show (+{groupedData[key].length - 5}) more
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      {!isSecondScreen && (
        <div
          style={{
            padding: '2%',
            paddingTop: '15%',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <div>
            <Button onClick={handleClose}>Back</Button>
          </div>
          <div>
            <Button variant="contained" onClick={() => handleStep(1)}>
              Next
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectLeaseModification;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Box from '@mui/material/Box';

function RedText({ children, sx }: any) {
  return (
    <Box
      component="p"
      sx={{
        border: 'none',
        fontWeight: 700,
        fontSize: '14px',
        color: '#F5222D',
        padding: '0 8px',
        ...sx
      }}>
      {children}
    </Box>
  );
}

export default RedText;
